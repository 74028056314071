import http from '../http';
let userApi = {}
//获取平台用户列表
const getPlatUserList = function (keywords, pageNo, userType) {
    const opts = {
        url: "/plat/api/pc/user/list",
        params: {
            keywords,
            pageNo,
            userType

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//编辑平台用户
const editPlatUser = function (editType, userCode, newPasswd) {
    const opts = {
        url: "/plat/api/pc/user/edit",
        params: {
            editType,
            userCode,
            newPasswd

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//获取平台应用列表
const getPlatUserApplicationList = function (keywords, pageNo) {
    const opts = {
        url: "/plat/api/pc/application/list",
        params: {
            keywords,
            pageNo,
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//编辑平台应用
const editPlatUserApplication = function (editType, userAppCode, day) {
    const opts = {
        url: "/plat/api/pc/user/application/edit",
        params: {
            editType,
            userAppCode,
            day

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//更新用户应用下组件
const updatePlatUserApplication = function (moduleVersion,userAppCode) {
    const opts = {
        url: "/plat/api/pc/template/module/update",
        params: {
            moduleVersion,
            userAppCode
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};


//联系我们-申请体验用户列表
const getPlatApplyList = function ( pageNo) {
    const opts = {
        url: "/plat/api/pc/experience/apply/list",
        params: {
            pageNo,
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default userApi = {
    getPlatUserList,
    editPlatUser,
    getPlatUserApplicationList,
    editPlatUserApplication,
    updatePlatUserApplication,
    getPlatApplyList

}