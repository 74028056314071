import http from '../http';
let packageApi = {}
//获取平台产品套餐列表
const getPlatProductPakcageList = function (keywords, pageNo, platProductCode, platTemplateCode) {
    const opts = {
        url: "/plat/api/pc/product/package/list",
        params: {
            keywords,
            pageNo,
            platProductCode,
            platTemplateCode

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取平台产品套餐详情
const getPlatProductPakcageInfo = function (platProductPackageCode, platProductCode) {
    const opts = {
        url: "/plat/api/pc/product/package/info",
        params: {
            platProductPackageCode,
            platProductCode

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取平台产品套餐详情
const editPlatProductPakcageInfo = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/product/package/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取平台产品套餐权益列表
const getPlatProductPakcageRightList = function (keywords, pageNo, packageCode) {
    const opts = {
        url: "/plat/api/pc/product/package/right/list",
        params: {
            keywords,
            pageNo,
            packageCode

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取平台产品套餐权益详情
const getPlatProductPakcageRightInfo = function (rightCode) {
    const opts = {
        url: "/plat/api/pc/product/package/right/info",
        params: {
            rightCode

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取平台产品套餐-权益编辑
const editPlatProductPakcageRightInfo = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/product/package/right/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//获取平台产品套餐-权益值-列表
const getPlatProductPakcageRightValueList = function (keywords, pageNo, packageRightCode) {
    const opts = {
        url: "/plat/api/pc/product/package/right/value/list",
        params: {
            keywords,
            pageNo,
            packageRightCode

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取平台产品套餐权益值详情
const getPlatProductPakcageRightValueInfo = function (packageRightVCode) {
    const opts = {
        url: "/plat/api/pc/product/package/right/value/info",
        params: {
            packageRightVCode
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取平台产品套餐-权益值编辑
const editPlatProductPakcageRightValueInfo = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/product/package/right/value/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default packageApi = {
    getPlatProductPakcageList,
    getPlatProductPakcageInfo,
    editPlatProductPakcageInfo,
    getPlatProductPakcageRightList,
    getPlatProductPakcageRightInfo,
    editPlatProductPakcageRightInfo,
    getPlatProductPakcageRightValueList,
    getPlatProductPakcageRightValueInfo,
    editPlatProductPakcageRightValueInfo,

}