const config = {


//  platformUrl: 'http://192.168.124.6:8577',
             platformUrl: '/p',
    // platformUrl: 'https://platform.server.hzhaya.com',
//        platformUrl: 'https://platform.hzhaya.com/p',


};
export default config;