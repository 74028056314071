<!-- 加载动画 -->
<template>
	<div class="loading flex_g vertical flex_center flex_column"  v-show="loading">
    <img src="@/assets/images/load_icon2.gif">
    <!-- <p>加载中.....</p> -->
  </div>
</template>

<script>
export default {
  name: 'loading',
  data(){
    return{
        loading:true,
    }
  },
  methods:{
    open(){
        this.loading = true;
    },
  	close(type){
      let that =this
      setTimeout(function(){
         that.loading = false;
      },500)
       
    }
  }
}
</script>

<style lang="scss" scoped>
.loading{
    width:100%;
    height:100%;
    position:absolute;
    bottom:0;
    left:0;
    overflow: hidden;
    z-index: 10000;
    background:#fff;
    opacity: 1;
    img{
        width:10rem ;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
    p{
    transform: translateY(50px);
    color:#999;
    font-size: 1.1rem;
    }
}

</style>