import http from '../http';
let productApi = {}
//获取产品列表
const getPlatProductList = function (keywords, pageNo) {
    const opts = {
        url: "/plat/api/pc/platProduct/list",
        params: {
            keywords,
            pageNo
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品详情
const getPlatProductInfo = function (platPorductCode) {
    const opts = {
        url: "/plat/api/pc/platProduct/info",
        params: {
            platPorductCode
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑产品
const editPlatProductInfo = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/platProduct/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 获取所有产品类目列表
const getProductCategoryAllList = function () {
    const opts = {
        url: "/plat/api/pc/category/all/list",
        params: {

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 获取产品类目列表
const getProductCategoryList = function (keywords, level, parentCategoryId) {
    const opts = {
        url: "/plat/api/pc/category/list",
        params: {
            pageNo: 1,
            keywords,
            level,
            parentCategoryId,
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};


// 平台类目编辑
const editProductCategory = function (type, data) {
    const opts = {
        url: "/plat/api/pc/category/edit?editType=" + type,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取模版公共路由地址
const getPlatProductCommonPageList = function (pageNo, platProductCode, routeType) {
    const opts = {
        url: "/plat/api/pc/template/common/page/route/list",
        params: {
            pageNo,
            platProductCode,
            routeType,
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑模版公共路由地址
const editPlatProductCommonPage = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/template/common/page/route/edit?editType="+editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default productApi = {
    getPlatProductList,
    getPlatProductInfo,
    editPlatProductInfo,
    getProductCategoryAllList,
    editProductCategory,
    getProductCategoryList,
    getPlatProductCommonPageList,
    editPlatProductCommonPage,
}