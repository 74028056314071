import http from '../http';
let templateApi = {}
//获取公共组件列表
const getTemplateModuleList = function (keywords, pageNo) {
    const opts = {
        url: "/plat/api/pc/template/module/common/list",
        params: {
            keywords,
            pageNo
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 获取公共组件详情
const getTemplateModuleInfo = function (commonModuleCode) {
    const opts = {
        url: "/plat/api/pc/template/module/common/info",
        params: {
            commonModuleCode
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 编辑公共组件
const editTemplateModuleInfo = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/template/module/common/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

// 获取所有模板类目列表
const getTemplateCategoryAllList = function () {
    const opts = {
        url: "/plat/api/pc/template/category/list",
        params: {

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 模板类目编辑
const editTemplateCategory = function (type, data) {
    const opts = {
        url: "/plat/api/pc/template/category/edit?editType=" + type,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//获取模版列表
const getTemplateList = function (keywords, pageNo) {
    const opts = {
        url: "/plat/api/pc/template/list",
        params: {
            keywords,
            pageNo
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取模版详情
const getTemplateInfo = function (plateTemplateCode) {
    const opts = {
        url: "/plat/api/pc/template/info",
        params: {
            plateTemplateCode,
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑模版
const editTemplateInfo = function (data) {
    const opts = {
        url: "/plat/api/pc/template/edit?editType=" + data.editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//获取模版页面列表
const getTemplatePageList = function (keywords, pageNo, platTemplateCode) {
    const opts = {
        url: "/plat/api/pc/template/page/list",
        params: {
            keywords,
            platTemplateCode,
            pageNo
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取模版页面详情
const getTemplatePageInfo = function (plateTemplateCode, platTemplatePageCode) {
    const opts = {
        url: "/plat/api/pc/template/page/info",
        params: {
            plateTemplateCode,
            platTemplatePageCode,
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑模版页面
const editTemplatePageInfo = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/template/page/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑模版页面组件
const editTemplatePageModuleInfo = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/template/page/module/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑模版页面内容组件
const editTemplatePageModuleParamInfo = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/template/page/module/parm/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取模版页面内容组-列表
const getTemplatePageModuleParamGroupList = function (keywords, pageNo, platTemplateCode, platTemplatePageCode, platTemplatePageMoudleCode) {
    const opts = {
        url: "/plat/api/pc/template/page/module/parm/group/list",
        params: {
            keywords,
            platTemplateCode,
            platTemplatePageCode,
            platTemplatePageMoudleCode,
            pageNo
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑模版页面内容组
const editTemplatePageModuleParamGroup = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/template/page/module/parm/group/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//获取模版下公共配置列表|parmType 1 底部栏 2 悬浮窗
const getTemplateCommonConfigList = function (parmType, platTemplateCode) {
    const opts = {
        url: "/plat/api/pc/template/common/config/list",
        params: {
            parmType,
            platTemplateCode,

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑模版下公共配置详情 editType 1 新增 2 编辑 3 删除
const editTemplateCommonConfig = function (editType, data) {
    const opts = {
        url: "/plat/api/pc/template/common/config/edit?editType=" + editType,
        params: data,
        apiUrl: 'common',
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取模版下公共配置详情
const getTemplateCommonConfigInfo = function (id) {
    const opts = {
        url: "/plat/api/pc/template/common/config/info",
        params: {
            id

        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

export default templateApi = {
    getTemplateModuleList,
    getTemplateModuleInfo,
    editTemplateModuleInfo,
    getTemplateList,
    getTemplateInfo,
    editTemplateInfo,
    getTemplatePageList,
    getTemplatePageInfo,
    editTemplatePageInfo,
    getTemplateCategoryAllList,
    editTemplateCategory,
    editTemplatePageModuleInfo,
    editTemplatePageModuleParamInfo,
    getTemplatePageModuleParamGroupList,
    editTemplatePageModuleParamGroup,
    getTemplateCommonConfigList,
    editTemplateCommonConfig,
    getTemplateCommonConfigInfo,

}