/**
 * {常量名、函数名}
 */
import http from './http';
import loginApi from './Api/loginApi';
import productApi from './Api/productApi';
import templateApi from './Api/templateApi';
import packageApi from './Api/packageApi';
import userApi from './Api/userApi';


//获取首页
const getIndexInfo = function () {
    const opts = {
        url: "/iot/api/pc/index",
        params: {},
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

/**
 * 获取文档
 * @returns {Promise<unknown>}
 */
const getAppArticleList = function (keywords,parentArticleId){
    const opts={
        url:"/plat/api/office/article/list",
        params:{
            keywords,
            parentArticleId
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'common'
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
/**
 * 获取文档详情
 * @returns {Promise<unknown>}
 */
const getAppArticleInfo = function (articleId){
    const opts={
        url:"/plat/api/office/article/info",
        params:{
            articleId,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'common'
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
/**
 * 编辑文章 1 新增 2 编辑 3 删除

 * @returns {Promise<unknown>}
 */
const editArticleInfo = function (data,editType) {
    const opts = {
        url: "/plat/api/pc/office/article/edit?editType="+editType,
        params:data,
        contentType: 'application/json',
        apiUrl: 'common'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
/**
 * 获取文档
 * @returns {Promise<unknown>}
 */
const getAgreementList = function (){
    const opts={
        url:"/plat/api/pc/office/agreement/list",
        params:{
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'common'
    }
    return new Promise((resolve,reject)=>{
        http.post(opts).then(res=>{
            //后期优化
            resolve(res);
        })
            .catch(err=>{
                reject(err);
            })
    });
};
export default function (Vue) {
    //添加全局API
    Vue.prototype.$api = {
        getIndexInfo,
        loginApi,
        productApi,
        templateApi,
        packageApi,
        userApi,
        getAppArticleList,
        getAppArticleInfo,
        editArticleInfo,
        getAgreementList
       

    }
}